import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Styled from './styled'

import { SocialShareLinkData } from '../../lib/social/utils'

interface SocialShareLinksProps {
  socialShareLinks?: SocialShareLinkData[]
  marginLeftAuto: boolean
}

const SocialShareLinks: React.FC<SocialShareLinksProps> = ({
  socialShareLinks,
  marginLeftAuto,
}) => {
  return (
    <Styled.SocialMediaMenu marginLeftAuto={marginLeftAuto}>
      {socialShareLinks &&
        socialShareLinks.map((shareLink) => (
          <li key={shareLink.name}>
            <a href={shareLink.url} className={shareLink.name} rel="noreferrer" target="_blank">
              <FontAwesomeIcon icon={shareLink.icon} />
            </a>
          </li>
        ))}
    </Styled.SocialMediaMenu>
  )
}

export default SocialShareLinks
