import React from 'react'
import { GlobalStyle } from '@designsforhealth/dfh-react-components'
import { Router } from '@gatsbyjs/reach-router'
import { PageProps } from 'gatsby'

import { ResearchPageTemplateQuery } from '../../graphql/gatsby'
import { ResearchPageContext } from '../../lib/pages'

import Head from '../../layouts/Head'
import Layout from '../global/Layout'
import Research from './Research'
import { ResearchRoute } from './ResearchRoute'

export type ResearchPageProps = Pick<
  PageProps<ResearchPageTemplateQuery, ResearchPageContext>,
  'data' | 'pageContext' | 'path'
>

const ResearchPage: React.FC<ResearchPageProps> = ({ data, path }) => {
  const { research } = data

  if (!research) {
    return null
  }

  return (
    <>
      <Head title="Loading" />
      <GlobalStyle includeTypography />
      <Layout>
        <Router>
          <ResearchRoute
            path={path}
            component={Research}
            roleBasedAccessControl={research?.roleBasedAccessControl}
            research={research}
          />
        </Router>
      </Layout>
    </>
  )
}

export default ResearchPage
