import { useLocation } from '@gatsbyjs/reach-router'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'

import Link from '../../../components/global/Link'
import Spinner from '../../../components/Spinner'
import { Maybe, SanityRoleBasedAccessControl } from '../../../graphql/gatsby'
import useLogAndCaptureError from '../../../hooks/useLogAndCaptureError'
import { isInvalidStateError, isUserBlockedError, useAuth } from '../../../lib/auth'
import { researchUrl } from '../../../lib/research/utils'
import { useSanityRoleBasedAccessControl } from '../../../lib/sanity/hooks/useSanityRoleBasedAccessControl'
import { LoginError } from '../../auth/LoginError'
import FullPageError from '../../FullPageError'
import { LoadingContainer } from './styled'

export const ResearchRoute = <
  // Note: `any` seems to be required for inference - may be related to bivariance
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TComponent extends React.ComponentType<any>,
  TParamProps extends Exclude<keyof React.ComponentProps<TComponent>, 'children'> = never
>({
  component,
  path,
  roleBasedAccessControl,
  paramProps: _paramProps,
  ...componentProps
}: {
  component: TComponent
  path: string
  roleBasedAccessControl: Maybe<SanityRoleBasedAccessControl> | undefined
  // props supplied by reach-router dynamic segments
  paramProps?: ReadonlyArray<TParamProps>
} & Omit<React.ComponentProps<TComponent>, TParamProps>): React.ReactElement => {
  const { hasAccess, isLoading: hasAccessLoading } = useSanityRoleBasedAccessControl({
    roleBasedAccessControl,
  })
  const { error, isAuthenticated, isLoading, loginWithRedirect } = useAuth()
  const location = useLocation()

  const isInvalidState = isInvalidStateError(error)
  const isUserBlocked = isUserBlockedError(error)

  // duplicate logic from `withAuthenticationRequired()` so error can be displayed
  useEffect(() => {
    if (hasAccess || hasAccessLoading || isAuthenticated || isLoading || isUserBlocked) {
      return
    }
    const opts = {
      appState: { returnTo: location ? `${location.pathname}${location.search}` : '' },
    }
    ;(async (): Promise<void> => {
      await loginWithRedirect(opts)
    })()
  }, [
    hasAccess,
    hasAccessLoading,
    isAuthenticated,
    isLoading,
    isUserBlocked,
    location,
    loginWithRedirect,
  ])

  useLogAndCaptureError(error, () => !isInvalidState && !isUserBlocked)

  if (error && !isInvalidState) {
    return <LoginError error={error} />
  }

  if (!hasAccessLoading && hasAccess) {
    return React.createElement(component, componentProps)
  }

  if (isAuthenticated && !hasAccess) {
    return (
      <FullPageError>
        <Helmet title="Restricted" />
        Access restricted
        <small>
          Please log in with an account authorized to access this resource or{' '}
          <Link to={researchUrl}>go back to Research</Link>
        </small>
      </FullPageError>
    )
  }

  return (
    <>
      <Helmet title="Loading" />
      <LoadingContainer>
        <Spinner loading />
      </LoadingContainer>
    </>
  )
}
