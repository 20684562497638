// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC, useEffect } from 'react'
import { RouteComponentProps } from '@gatsbyjs/reach-router'
import { libraryUrl } from '../../../lib/library'
import { usePageUrl } from '../../../lib/pages'

export type AssetRootRouteProps = RouteComponentProps

// NOTE: used this method instead of a `<Redirect />` because we need to redirect outside of
// the `<Router />` base path
export const AssetRootRoute: FC<AssetRootRouteProps> = () => {
  const { navigate } = usePageUrl()
  useEffect(() => {
    navigate(libraryUrl)
  }, [navigate])
  return null
}
