import React from 'react'
import { Helmet } from 'react-helmet-async'
import { ResearchPageTemplateQuery } from '../../../graphql/gatsby'
import { getSocialShareLinks } from '../../../lib/social/utils'
import { getGatsbyImageDataForAsset } from '../../../utils/sanity/image'
import SocialShareLinks from '../../social/SocialShareLinks'

import * as Styled from './styled'

export interface ResearchProps {
  className?: string
  research: NonNullable<ResearchPageTemplateQuery['research']>
}

const Research: React.FC<ResearchProps> = ({ research }) => {
  const {
    mainImage,
    showSocialLinks,
    title,
    _rawHeaderImage: rawHeaderImage,
    _rawHeader: rawHeader,
    _rawBody: rawBody,
    _rawMainImage: rawMainImage,
  } = research

  const mainGatsbyImage = getGatsbyImageDataForAsset(rawMainImage, {
    width: 1200,
  })

  const headerImage = getGatsbyImageDataForAsset(rawHeaderImage, {
    width: 1200,
  })

  const href = typeof window !== 'undefined' ? window.location.href : ''
  const socialShareLinks =
    href && showSocialLinks !== false ? getSocialShareLinks(href, title) : undefined

  return (
    <>
      <Helmet title={title || ''} />
      <Styled.PageHero
        title="Research Initiative"
        isTitleTopLevelHeader={false}
        heroImage="dfh-pattern-gray"
        heroTag={{
          tag: `Designs for Health has been the health care professional's trusted source since 1989.`,
        }}
      />
      {mainGatsbyImage && (
        <Styled.MainImage image={mainGatsbyImage} alt={mainImage?.alt || ''}>
          {mainImage?.caption && <Styled.Summary>{mainImage.caption}</Styled.Summary>}
        </Styled.MainImage>
      )}
      {!!(headerImage || rawHeader) && (
        <Styled.Header>
          {!!headerImage && <Styled.HeaderImage image={headerImage} alt="" />}
          {!!rawHeader && <Styled.BlockContent blocks={rawHeader} />}
        </Styled.Header>
      )}
      <Styled.Paper>
        <Styled.PaperContent>
          {rawBody && <Styled.BlockContent blocks={rawBody} />}
          {socialShareLinks && (
            <SocialShareLinks marginLeftAuto={false} socialShareLinks={socialShareLinks} />
          )}
        </Styled.PaperContent>
      </Styled.Paper>
    </>
  )
}

export default Research
