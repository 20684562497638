import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Maybe } from '../../graphql/gatsby'

export interface SocialShareLinkData {
  name: string
  icon: IconProp
  url: string
}

export const getSocialShareLinks = (
  href: string,
  title: Maybe<string> | undefined
): SocialShareLinkData[] => {
  const hrefEncoded = encodeURIComponent(href)
  const titleEncoded = encodeURIComponent(title as string)
  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${hrefEncoded}`
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${hrefEncoded}&text=${titleEncoded}`
  const linkedinShareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${hrefEncoded}&title=${titleEncoded}`
  return [
    {
      name: 'facebook',
      icon: ['fab', 'facebook-f'],
      url: facebookShareUrl,
    },
    {
      name: 'twitter',
      icon: ['fab', 'twitter'],
      url: twitterShareUrl,
    },
    {
      name: 'linkedin',
      icon: ['fab', 'linkedin-in'],
      url: linkedinShareUrl,
    },
  ]
}
