import React, { FC, useEffect, useMemo } from 'react'
import Link from '../../global/Link'
import styled from 'styled-components'
import { Alert } from '@designsforhealth/dfh-react-components'
import { useAuth } from '../../../lib/auth'
import { useSessionAuth } from '../../../lib/auth/hooks/useSessionAuth'
import {
  buildResourceAssetUrl,
  isProfessionalResourceTypeSlug,
  isPublicResourceTypeSlug,
  isUserCategoryProfessional,
  libraryUrl,
  ResourceTypeSlug,
} from '../../../lib/library'
import useUserCategory from '../../../hooks/useUserCategory'
import Spinner from '../../Spinner'

export interface AssetProps {
  type: ResourceTypeSlug
  slug: string
}

const ErrorContainer = styled.div`
  padding: 30px 15px;
  display: flex;
  justify-content: center;
  p {
    margin: 0;
  }
`

export const Asset: FC<AssetProps> = ({ type, slug }) => {
  const { isAuthenticated, isLoading: isLoadingAuth } = useAuth()
  const { called: calledSession, loading: loadingSession, error: errorSession } = useSessionAuth()

  const { userCategory } = useUserCategory()

  const isSessionAuthSet = useMemo(() => {
    return calledSession && !loadingSession && !errorSession
  }, [calledSession, errorSession, loadingSession])

  const isProfessionalType = useMemo(() => {
    return !isPublicResourceTypeSlug(type) && isProfessionalResourceTypeSlug(type)
  }, [type])

  const isProfessionalUser = useMemo(() => {
    return (
      !isLoadingAuth && isAuthenticated && userCategory && isUserCategoryProfessional(userCategory)
    )
  }, [isAuthenticated, isLoadingAuth, userCategory])

  const shouldLoadAsset = isSessionAuthSet && (!isProfessionalType || isProfessionalUser)
  const isForbidden = isSessionAuthSet && isProfessionalType && !isProfessionalUser

  useEffect(() => {
    if (shouldLoadAsset) {
      window.location.href = `${window.location.origin}${buildResourceAssetUrl(type, slug)}`
    }
  }, [slug, type, shouldLoadAsset])

  if (!isForbidden) {
    return <Spinner loading />
  }
  return (
    <ErrorContainer>
      <Alert type="error">
        <p>
          Please log in with a practitioner account to access this resource or{' '}
          <Link to={libraryUrl}>go back to Library</Link>
        </p>
      </Alert>
    </ErrorContainer>
  )
}
